import { getDataYlkString, type I13NSec } from "@yahoo-creators/i13n";
import { NativeAd } from "@yahoo-creators/monetization";
import classNames from "classnames";
import React, { type FC } from "react";
import { useNcpCacheContext } from "@/contexts/NcpCacheContext";
import { type ShoppingConfigItemsType } from "@/lib/astronomer/types";
import { I13nStartIndexContext } from "@/lib/I13nStartIndexContext";
import { type StoryItem as StoryItemType } from "@/lib/streamDataUtils";
import { StoryItem } from "./StoryItem";
import { StoryItemPlaceholders } from "./StoryItemPlaceholders";
import { StreamAdPlaceholder } from "./StreamAdPlaceholder";

type Props = {
  adFrequency?: number;
  className?: string;
  enableAds?: boolean;
  firstAdIndex?: number;
  firstStoryIndex?: number;
  i13nSec: I13NSec;
  i13nSubsec?: string;
  imageFormat?: string;
  items: StoryItemType[];
  mixedStreamLayout?: boolean;
  onPlaceholdersVisible: () => void;
  shoppingConfigItems?: ShoppingConfigItemsType[] | null;
  showPlaceholders: boolean;
  taboolaPosition?: {
    mode?: string;
    placement?: string;
    region?: string;
  };
  wrapperClassNames?: string;
};

const gridColClasses =
  "grid-cols-[110px_auto] sm:grid-cols-2 lg:grid-cols-9 xl:grid-cols-[292px_auto]";

export const StoryStackWithPagination: FC<Props> = ({
  adFrequency = 6,
  enableAds = true,
  firstAdIndex = 1,
  firstStoryIndex = 0,
  i13nSec,
  i13nSubsec,
  items = [],
  onPlaceholdersVisible,
  showPlaceholders,
  taboolaPosition,
  wrapperClassNames,
}) => {
  const { articlePosition } = useNcpCacheContext();

  return (
    <section
      className={classNames(
        !wrapperClassNames
          ? "col-span-full lg:col-span-1 xl:col-span-8"
          : wrapperClassNames,
      )}
      data-ylk={getDataYlkString({
        sec: i13nSec,
        subsec: i13nSubsec,
      })}
    >
      <ul className="flex flex-col gap-4 sm:gap-6">
        {items.map((story, index) => {
          const currentIndex = firstStoryIndex + index;
          const shouldRenderAd =
            enableAds && (currentIndex - firstAdIndex) % adFrequency === 0;

          const taboolaPlacement = taboolaPosition?.placement;
          const placement = `${taboolaPlacement ? taboolaPlacement : "taboola-stream-rocket"}-${currentIndex}`;

          return (
            <React.Fragment key={`fragment-${currentIndex}`}>
              {shouldRenderAd && (
                <li
                  className={classNames(
                    "relative min-h-[110px] w-full md:min-h-[145px]",
                  )}
                  key={placement}
                >
                  <StreamAdPlaceholder
                    className={classNames("absolute inset-0", gridColClasses)}
                  />
                  <NativeAd
                    className="group relative inset-0 rounded focus-within:ring-2 focus-within:ring-blue-700"
                    placement={placement}
                  />
                </li>
              )}

              <I13nStartIndexContext.Provider
                key={`storyStackWithPaginationStreamItem-${story.id}`}
                value={index}
              >
                <StoryItem
                  attribution={story.attribution}
                  badges={story.badges}
                  bypassModal={story.bypassModal}
                  cacheId={story.cacheId}
                  categoryLabel={story.categoryLabel}
                  commentCount={0}
                  i13nSubsec={i13nSubsec}
                  id={story.id}
                  key={story.cacheId}
                  selectedStory={articlePosition.stream}
                  summary={story.summary}
                  thumbnail={story.thumbnail}
                  title={story.title}
                  url={story.url}
                  viewCount={story.viewCount}
                  wpm200={story.wpm200}
                />
              </I13nStartIndexContext.Provider>
            </React.Fragment>
          );
        })}
      </ul>
      {showPlaceholders && (
        <StoryItemPlaceholders
          className={gridColClasses}
          onPlaceholdersVisible={onPlaceholdersVisible}
        />
      )}
    </section>
  );
};
